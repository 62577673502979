$color-fore: #29CF8A;
$color-back: #455368;

$color-white: #ffffff;
$color-black: #000000;

$color-grey:  #5b5b5b;
$color-dark:  #444444;
$color-light: #aaaaaa;

$color-yellow: #ffff00;
$color-red:    #e31e24;
$color-green:  #00b324;

$font-ubuntu: 'Ubuntu', sans-serif;
$font-work-sans: 'Work Sans', sans-serif;