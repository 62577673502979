.alternative-wrap {
    background:$color-grey;
    padding-top: 1px;

    .content {
        color:$color-white;
    }
}


.content {
    color:$color-dark;
    //font-family: $font-work-sans;

    p {
        line-height:px2rem(22);
    }

    a {
        color:$color-red;
        text-decoration:none;

        &:hover {
            text-decoration:underline;
        }
    }

    .text-center {
        text-align: center;
    }

    .alt-title {
        font-size: 1.25rem;
        font-family: "Work Sans", sans-serif;
        font-weight: 300;
        margin: 0 auto 0 auto;
    }

    .image-fit {
        width:100%;
    }

    .image-wrap {
        box-sizing: border-box;
        border-width:px2rem(1);
        border-style:solid;
        border-image:linear-gradient(to top, $color-light, rgba(0, 0, 0, 0)) 1 1%;
    }

    ul.list {
        padding-left:px2rem(30);

        li {
            margin-left:px2rem(-30);
            padding-left:px2rem(30);
            padding-bottom:px2rem(6);
            line-height:px2rem(16);
        }

        &.list-arrow {
            li {
                list-style: none;
                background: url("../image/list-dot-arrow.svg") no-repeat left top;
                background-size: px2rem(16, 16);
            }
        }
    }

    .link-button {
        display:block;
        box-sizing:border-box;
        border:px2rem(4) solid $color-grey;
        border-radius: px2rem(8);
        background:$color-white;
        transition: background 0.5s;
        cursor:pointer;


        margin: px2rem(20) auto px2rem(10) auto;
        padding: px2rem(5,0,5,0);

        line-height:px2rem(20);
        text-align:center;
        text-decoration:none;
        color:$color-grey;

        span {display:block;}

        &:hover {
            background:$color-grey;
            color:$color-white;
            text-decoration:none !important;
        }

        @at-root {
            .content a:hover .link-button {
                background:$color-grey;
                color:$color-white;
            }
        }
    }

    .fadeEffect {
        opacity:0;
        transform:translate(0,px2rem(-10));
        filter: blur(10);

        &.fadeIn {
            transition:2s;
            opacity:1;
            transform:translate(0,0);
            filter: blur(0);
        }

        @for $i from 1 through 5 {
            @include respond-from('tablet') {
                &.delay-#{$i} {
                    transition-delay: #{$i / 2}s;
                }
            }
        }
    }

    .splitter {
        &.splitter-light {
            width:60%;
            margin-left:auto;
            margin-right:auto;
            margin-top:px2rem(30);
            border-top:px2rem(1) solid $color-light;
            padding-top:px2rem(30);
        }
    }

    dl {
        clear:left;
        line-height:1.5rem;
        dt {clear:left; float:left; width:px2rem(100); text-align:right; padding-right:1rem; color: $color-light}
        dd {font-weight:bold;}
    }
}

// intro page

.intro-slider {
    position:relative;

    box-sizing: content-box;
    padding-bottom:47.3684210526%;
    overflow:hidden;

    @include respond-from('tablet') {
        margin-top:px2rem(10);
    }

    .slide {
        position:absolute;
        width: 100%;
        opacity:0;
        z-index:0;
        transition: opacity 2s;

        img {
            position:relative;
            z-index:1;
            width:100%;
            height:auto;
        }

        .text {
            position:absolute;
            z-index:2;
            left:3%;
            top:40%;
            color:white;
            font-size:px2rem(16);
            font-weight:bold;
            text-shadow:0 0 px2rem(2) $color-grey;
            border-top:px2rem(2) solid $color-white;
            border-bottom:px2rem(2) solid $color-white;
            background:rgba($color-grey,0.5);
            overflow:hidden;

            transform:scaleY(0);

            span {
                display:block;
                margin:px2rem(5,10,5,10);
                opacity:0;
            }
        }
    }

    .slide.prev {
        z-index:1;
        opacity:1;
        img {
            transition:10s;
            transform: scaleX(1.1) scaleY(1.1);
        }
    }

    .slide.current {
        z-index:2;
        opacity:1;
        img {
            transition:10s;
            transform: scaleX(1.1) scaleY(1.1);
        }
        .text {
            transition:transform 1s;
            transition-delay:1s;
            transform:scaleY(1);
            span {
                opacity:1;
                transition:opacity 1s;
                transition-delay:2s;
            }
        }
    }

    .logo {
        position:absolute;
        z-index:100;
        left:3%;
        top:5%;
        width:35%;
        height:25%;

        .fore, .back {
            position:absolute;
            width:100%; height:100%;
        }
        .fore {z-index:2;}
        .back {
            z-index:1;

            @for $i from 1 through 5 {
                &.back-#{$i} {
                    filter:blur(px2rem($i));
                }
            }
        }

        &.carbon-technology {
            .fore {background:url('../image/logo-carbon.svg') no-repeat; background-size: contain;}
            .back {background:url('../image/logo-carbon-white.svg') no-repeat; background-size: contain;}
        }
        &.climbing-walls {
            .fore {background:url('../image/logo-climbing.svg') no-repeat; background-size: contain;}
            .back {background:url('../image/logo-climbing-white.svg') no-repeat; background-size: contain; }
        }
    }
}

a.intro-product {

    display:block;
    position:relative;
    text-decoration:none;
    color:$color-grey;

    .imgage, .image img {
        width:100%;

        border-width:px2rem(1);
        border-style: solid;
        border-image:linear-gradient(to top, $color-light, rgba(0, 0, 0, 0)) 1 1%;
    }

    &:hover {
        text-decoration:none;
    }
}

.item-product {

    @include respond-to($media-phone) {
        padding-left:px2rem(10);
        padding-right:px2rem(10);
        box-sizing:border-box;
    };

    .column-product {
        margin-bottom:px2rem(10);
        background:#eee;

        border-width:px2rem(1);
        border-style: solid;
        border-image:linear-gradient(to top, $color-light, rgba(0, 0, 0, 0)) 1 1%;


        //box-shadow:
        //    0 0 px2rem(20) #ddd inset,
        //    0 px2rem(3) px2rem(3) #ddd;

        @include respond-to($media-phone) {
            padding-left:px2rem(16);
        };
    }

    h3 {
        font-size:px2rem(14);
    }

    .product-slider {
        position:relative;
        float:right;
        width:40%;
        box-sizing: content-box;
        overflow:hidden;
        margin:px2rem(10,0,10,0);

        border-width:px2rem(1);
        border-style: solid;
        border-image:linear-gradient(to top, $color-light, $color-white) 1 1%;

        @include respond-to($media-phone) {
            float:none;
            width:100%;
        };

        .slides {padding-bottom:62.5%;}

        .slide {
            position:absolute;
            width: 100%;
            opacity:0;
            z-index:0;
            transition: opacity 2s;

            img {
                position:relative;
                z-index:1;
                width:100%;
                height:auto;
            }
        }

        .slide.current {
            z-index:2;
            opacity:1;
        }
    }
}

.walls-categories {
    padding:0;
    margin:0;

    .category {
        padding:0;
        margin:0 0 px2rem(5) 0;
        list-style:none;

        a {
            position:relative;
            display:block;
        }

        .title {
            position:absolute;
            top:30%;
            z-index:3;
            width:100%;
            font-size:px2rem(40);
            color:$color-dark;
            text-align: center;
            text-shadow:
                0 0 px2rem(1) #fff,
                0 0 px2rem(2) #fff,
                0 0 px2rem(3) #fff,
                0 0 px2rem(4) #fff,
                0 0 px2rem(5) #fff,
                0 0 px2rem(10) #fff;

            @include respond-to($media-phone) {
                font-size: px2rem(20);
            }
        }

        .color {
            position:absolute;
            z-index:2;
            width:100%;
            height:100%;
            opacity:0.5;
            transition:opacity 0.5s;
        }

        &:hover .color {
            opacity:0.9;
        }

        .image {
            position:relative;
            z-index:1;
            line-height:0;

            img {
                width:100%;
                margin:0;
                padding:0;
            }
        }

        transition: transform 0.5s;
        transform: scaleY(0);

        &.effect-loaded {
            transform: scaleY(1);
        }

        @for $i from 1 through 6 {
            &.effect-delay-#{$i} {
                transition-delay: #{$i / 5}s;
            }
        }
    }
}

.gallery-slider {
    min-height:px2rem(1);
    position:relative;
    width:100%;
    height:0;
    padding-bottom:55%;

    border:px2rem(2) solid $color-grey;
    border-radius:px2rem(4);
    overflow:hidden;
    box-sizing: border-box;
    white-space: nowrap;
    background:$color-grey;

    .slide {
        position:absolute;
        display:block;
        width:100%;
        opacity:0;
        z-index:0;
        transition: opacity 2s;

        img {
            width:100%;
            vertical-align: middle
        }
    }

    .slide.current {
        z-index:2;
        opacity:1;
    }

    .bullets {
        position:absolute;
        z-index:5;
        left:px2rem(20);
        bottom:px2rem(20);
    }

    .bullet {
        float:left;
        width:px2rem(30);
        height:px2rem(30);
        border:px2rem(4) solid $color-grey;
        border-radius:px2rem(8);
        background: $color-white;
        margin-left:px2rem(5);
        cursor:pointer;

        &.current {
            background:$color-red;
        }

        @include respond-to($media-phone) {
            width:px2rem(20);
            height:px2rem(20);
        }
    }
}

.price-list {
    width:100%;
    td {
        padding:px2rem(4,8,4,8);
    }

    td:last-child {
        width:20%;
        text-align:right;
    }
    tr {
        background:$color-white - 20%;
    }

    tr:nth-child(2n) {
        background:$color-white - 10%;
    }
}

form {

    .element {
        position:relative;
        background:white;
        border:1px solid $color-white;
        border-radius:px2rem(4);
        margin-bottom:px2rem(4);
        box-shadow: inset 0 0.2rem 0.2rem #ddd;
        line-height:1rem;
        font-size:1rem;
        font-weight:normal;

        &.element-area {
            height:10rem;
        }

        input, textarea {
            position:relative;
            z-index:2;
            width:100%;
            border:0;
            border-radius:0.4rem;
            background:transparent;
            padding:0.5rem;
            box-sizing: border-box;
            color:black;
        }

        textarea {
            height:100%;
        }

        .text {
            position:absolute;
            z-index:1;
            left:0.5rem; top:0.5rem;
            color:$color-light;
            font-weight:normal;
        }

        &.icon {
            .icon {
                color:$color-dark;
                position:absolute;
                z-index:3;
                left:0.5rem; top:0.5rem;
            }

            .text {left:2rem;}
            input, textarea {padding-left:2rem;}
        }

        &.button {
            background: $color-grey;
            border:px2rem(4) solid $color-white;
            box-shadow:none;

            .text-center {
                position:absolute;
                top:0.5rem;
                width:100%;
                text-align: center;

                .icon, .text {position:relative; left:0; top:0; display:inline; color:white}
            }

            &, & input {cursor:pointer}
        }
    }

    .message {
        background: $color-green + 20%;
        border-radius:px2rem(4);
        margin-bottom:1rem;
        padding:0.4rem 2rem 0.4rem 2rem;
        box-shadow: px2rem(0,2,4) $color-dark;

        &.error {
            background: $color-red;
        }
    }
}

.row-contact {
    text-align:center;
    box-sizing:border-box;

    ul.icons {
        text-align:left;
        margin:px2rem(12,0,0,0);
        padding:0;

        li {
            margin:0;
            padding:0;
            list-style: none;
            color:$color-grey;
            padding:px2rem(0,6,6,12);
            line-height:px2rem(38);
            @extend .clearfix;

            a {
                display:block;
                width:100%;
                text-decoration:none;
                color:$color-grey;
                cursor:pointer;
            }

            .icon {
                border:px2rem(4) solid $color-grey;
                border-radius: px2rem(8);
                transition:0.5s;
                display:block;
                float:left;
                width:px2rem(32);
                height:px2rem(32);
                margin-right:px2rem(12);
                background:center center no-repeat;
                background-size: px2rem(24,24);

                &.phone     {background-image:url('../svg/grey/phone-call.svg');}
                &.mail      {background-image:url('../svg/grey/email.svg');}
                &.facebook  {background-image:url('../svg/grey/facebook.svg');}
                &.instagram {background-image:url('../svg/grey/instagram.svg');}
                &.youtube   {background-image:url('../svg/grey/youtube.svg');}
            }

            a:hover {
                .icon {
                    background-color:$color-grey;

                    &.phone     {background-image:url('../svg/white/phone-call.svg');}
                    &.mail      {background-image:url('../svg/white/email.svg');}
                    &.facebook  {background-image:url('../svg/white/facebook.svg');}
                    &.instagram {background-image:url('../svg/white/instagram.svg');}
                    &.youtube   {background-image:url('../svg/white/youtube.svg');}
                }
            }

            .text-light {
                color:$color-light;
            }

            //<li><a href="#"><span class="icon youtube"></span>youtube</a></li>
        }
    }

    @include respond-for($media-tablet) {
        .columns {
            float:none;
            width:80%;
            margin-left:15%;
        }
    }
}