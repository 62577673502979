$geck-config-grid-max-width: 1000;
$geck-config-grid-total-columns: 12;

.row {
    width:100%;
    max-width: #{$geck-config-grid-max-width}px;
    //margin: 0 auto ;

    @extend .clearfix;

    &.distance {
        height:2rem;
    }

    .section-general & {
        margin:0 auto;
    }
}

.column {
    padding-left:1rem;
    padding-right:1rem;
    box-sizing: border-box;

    &.no-device-margin {
        @include respond-to('tablet') {
            padding: 0;
        }
    }

    &.centered {
        float:none;
        margin-left:auto;
        margin-right:auto;
    }

    &.splitter {
        margin-left:px2rem(10);
        margin-right:px2rem(10);
        border-bottom:px2rem(1) solid $color-light;
    }
}

.columns {
    @extend .column;
    float:left;
    padding-left:1.5rem;

    &.keep-space {
        height:px2rem(1);
    }

    &.column-light-line {
        @include respond-from($media-phone) {
            border-left: px2rem(1) solid $color-light;
            box-sizing: border-box;
        }
    }
}

@for $i from 1 through $geck-config-grid-total-columns {
    .column-#{$i} {
        width: #{100% / (12 / $i)};
    }

    @include respond-to('phone') {
        .column.column-#{$i} {
            width: 100%;
        }
    }
}
