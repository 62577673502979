.section-general {

    .intro-frames {
        position:relative;
        width:100%;
        overflow-x:hidden;

        // overall frame
        .frame {
            position:relative;
            float:left;
            width:50%;
            //border:1px solid yellow;
            box-sizing: border-box;

            &:hover {
                .part-logo {
                    .logo-hover {opacity: 1;}
                    .logo-blur {opacity: 0;}
                }
                .part-image {
                    .image {filter: blur(1px); background-size: 102% 102%;}
                    .cover {opacity:0.5}
                    .shadow {opacity:1}
                }
                .part-button {
                    .link {opacity:1; transform:scale(1,1); filter:blur(0)}
                }
            }
        }

        // logo
        .part-logo {
            width:100%;
            margin:px2rem(5,0,5,0);

            .logo-wrap {
                position:relative;
                width: px2rem(300);
                max-width:70%;
                height:px2rem(140);
                margin:0 auto 0 auto;
                padding-top:px2rem(10);

                .logo-blur, .logo-hover {
                    position:absolute;
                    width:100%;
                    height:100%;
                    transition: opacity 0.5s;
                }

                .logo-hover {opacity:0;}
                .logo-blur {opacity:1;}
            }
        }

        // image
        .part-image {
            height: calc(20vw + #{px2rem(40)});

            .image-wrap {
                position: relative;
                width: 60vw;
                height: 20vw;
                transform: skewX(-25deg);
                overflow: hidden;
                box-shadow: 0px 1px 5px $color-red inset;
            }

            .image {
                position:relative;
                z-index:1;
                width:100%;
                height:100%;
                transform: skewX(25deg);
                background: $color-grey url('../image/front-roller-ski.jpg') center center;
                background-size: 100% 100%;
                transition: filter 0.5s, background-size 0.5s;
            }

            .cover, .shadow {
                position:absolute;
                z-index:2;
                left:0; top:0;
                width:100%;
                height:100%;
                opacity:0;
                transition: opacity 0.5s;
            }

            .shadow {z-index:3;}
        }

        // button in the image
        .part-button {
            position:absolute;
            top: px2rem(170);
            width:100%;

            .link {
                position:relative;
                display: block;
                box-sizing: border-box;
                border: px2rem(4) solid $color-white;
                border-radius: px2rem(8);

                width: px2rem(300);
                max-width:80%;
                margin: px2rem(20) auto px2rem(10) auto;
                padding: px2rem(5, 0, 5, 0);

                cursor:pointer;
                line-height: px2rem(20);
                text-align: center;
                text-decoration: none;
                color: $color-white;
                text-shadow:0 0 px2rem(10) $color-black;
                box-shadow:0 0 px2rem(10) $color-black, 0 0 px2rem(10) $color-black inset;

                opacity:0;
                transform:scale(1.2,1.2);
                filter:blur(px2rem(3));
                transition: 0.5s;

                span {display: block;}

                &:hover {
                    background: $color-white;
                    color: $color-dark;
                    text-shadow:0 0 px2rem(0) $color-black;
                    box-shadow:0 0 px2rem(10) $color-black, 0 0 px2rem(0) $color-white inset;
                }
            }
        }

        // left / first
        .carbon-technology {
            .part-logo {
                border-right:px2rem(1) solid $color-grey;
                .logo-blur {
                    background: url('../image/logo-carbon-blur.svg') no-repeat;
                    background-size: 100% 80%;
                }
                .logo-hover {
                    background: url('../image/logo-carbon.svg') no-repeat;
                    background-size: 100% 80%;
                }
            }
            .part-image {
                .image-wrap {left:-10vw}
                .image {left:5vw;}
                .cover {background:$color-red;}
                .shadow {box-shadow: 0 0 px2rem(40) $color-red inset;}
            }
        }

        // right / last
        .climbing-walls {
            .part-logo {
                .logo-blur {
                    background: url('../image/logo-climbing-blur.svg') no-repeat;
                    background-size: 100% 100%;
                }
                .logo-hover {
                    background: url('../image/logo-climbing.svg') no-repeat;
                    background-size: 100% 100%;
                }
            }
            .part-image {
                .image-wrap {top:px2rem(40); left:0;}
                .image {left:-5vw; background-image: url('../image/front-climbing-walls.jpg');}
                .cover {background:$color-yellow;}
                .shadow {box-shadow: 0 0 px2rem(40) $color-yellow inset;}
            }
            .part-button {
                .link {top:px2rem(40)}
            }
        }
    }

    // text
    .intro-text {
        position:relative;
        width:100%;
        color: $color-grey;

        .part-text {
            position: relative;
            float: left;
            width: 50%;
            margin:px2rem(5,0,5,0);
            box-sizing: border-box;

            &.carbon-technology {
                border-right:px2rem(1) solid $color-grey;
            }
            &.climbing-walls {
                left: px2rem(-1);
                border-left:px2rem(1) solid $color-grey;
            }
        }

        // logo
        .logo-wrap {
            display:none;
            width:100%;
            margin:px2rem(10,0,5,0);

            .logo {
                display:block;
                position:relative;
                width: px2rem(300);
                max-width:70%;
                height:px2rem(150);
                margin:0 auto 0 auto;
            }
        }

        // left / first
        .carbon-technology .logo-wrap .logo {
            background: url('../image/logo-carbon.svg') no-repeat;
            background-size: 100% 80%;
        }

        // right / last
        .climbing-walls .logo-wrap .logo {
            background: url('../image/logo-climbing.svg') no-repeat;
            background-size: 100% 100%;
        }

        .text-wrap {
            position: relative;
            width: px2rem(400);
            max-width: 90%;
            margin: 0 auto 0 auto;
            padding-bottom: px2rem(10);

            .text-link {
                display:block;
                box-sizing:border-box;
                border:px2rem(4) solid $color-grey;
                border-radius: px2rem(8);
                transition:0.5s;

                max-width: px2rem(300);
                margin: px2rem(20) auto px2rem(10) auto;
                padding: px2rem(5,0,5,0);

                line-height:px2rem(20);
                text-align:center;
                text-decoration:none;
                color:$color-dark;
                cursor:pointer;

                span {display:block;}

                &:hover {
                    background:$color-grey;
                    color:$color-white;
                }
            }
        }
    }

    @include respond-to('phone') {
        .intro-frames {
            display: none;
        }
        .intro-text {
            .logo-wrap {
                display:block;
            }

            .part-text {
                float:none;
                width: 100%;
                box-sizing: border-box;

                &.carbon-technology {
                    border:0;
                    border-bottom:px2rem(1) solid $color-grey;
                    margin-bottom:px2rem(25);
                }
            }
        }
    }
}