$geck-config-unit-one-rem: 16;

@function px2rem($size...) {

    $sizes: $size;
    $max: length($sizes);
    $remValues: '';

    @for $i from 1 through $max {
        $size: nth($sizes, $i);
        $value: $size / $geck-config-unit-one-rem;

        @if $value == 0 {
            $remValues: #{$remValues $value};
        } @else {
            $remValues: #{$remValues $value}rem;
        }

        @if $i < $max {
            $remValues: $remValues + ' ';
        }
    }

    @return $remValues;
}