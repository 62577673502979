.section-carbon-technology {

    .intro-frames {
        margin-top:px2rem(20);
        position: relative;
        width: 100%;
        height: 20vw;
        overflow-x: hidden;

        .frame {
            position: relative;
            float: left;
            width: 50%;
            height: 20vw;
            box-sizing: border-box;

            .image-wrap {
                position: relative;
                width: 60vw;
                height: 20vw;
                overflow: hidden;
                transform: skewX(-25deg);

                .image {
                    position: relative;
                    z-index: 1;
                    left:-5vw;
                    width: 100%;
                    height: 100%;
                    transform: skewX(25deg);
                    background: url('../image/front-roller-ski.jpg') left center;
                    background-size: 100% 100%;
                    transition-duration: 0.5s;
                }
            }

            .logo {
                height:50%;
                margin:px2rem(0,10,40,10);
                background: url('../image/logo-carbon.svg') no-repeat;
                background-size: 100% 100%;
                border-bottom:1px solid $color-grey;
                //border:1px solid red;
            }
        }
    }
}