// Media queries:
// min-width:320px  - smartphones, iPhone, portrait 480x320 phones
// min-width:481px  - portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide.
// min-width:641px  - portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones
// min-width:961px  - tablet, landscape iPad, lo-res laptops ands desktops
// min-width:1025px - big landscape tablets, laptops, and desktops
// min-width:1281px - hi-res laptops and desktops

$break-small:  641px;
$break-medium: 961px;
$break-wide:  1400px;

$media-phone:  'phone';
$media-tablet: 'tablet';
$media-wide:   'wide';

@mixin respond-for($media) {
    @if $media == $media-phone {
        @media only screen and (max-width: $break-small) { @content; }
    }
    @else if $media == $media-tablet {
        @media only screen and (min-width: $break-small + 1) and (max-width: $break-medium) { @content; }
    }
    @else if $media == $media-wide {
        @media only screen and (min-width: $break-wide + 1) { @content; }
    }
}

@mixin respond-to($media) {
    @if $media == $media-phone {
        @media only screen and (max-width: $break-small) { @content; }
    }
    @else if $media == $media-tablet {
        @media only screen and (max-width: $break-medium) { @content; }
    }
    @else if $media == $media-wide {
        @media only screen and (max-width: $break-wide) { @content; }
    }
}

@mixin respond-from($media) {
    @if $media == $media-phone {
        @media only screen and (min-width: $break-small + 1) { @content; }
    }
    @else if $media == $media-tablet {
        @media only screen and (min-width: $break-medium + 1) { @content; }
    }
    @else if $media == $media-wide {
        @media only screen and (min-width: $break-wide + 1) { @content; }
    }
}