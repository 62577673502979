body {
    overflow-y:scroll;
}

header {
    position:relative;
    z-index:2;
    width:100%;
    height: px2rem(60);
    font-family: $font-work-sans;
    background:$color-grey;
    color:$color-white;

    //MENU BUTTON
    .menu {

        float:left;
        height: px2rem(60);
        width: px2rem(140);

        a {
            display:block;
            cursor: pointer;
            width:100%;
            height:100%;
            text-decoration:none;
        }

        .icon {
            float:left;
            width: px2rem(60);
            height: px2rem(40);
            margin:0;
        }

        .text {
            float:left;
            padding-top: px2rem(10);
            line-height:px2rem(40);
            font-size:px2rem(20);
            font-weight:500;
            color:$color-white;
        }

        .icon.button svg {
            padding-top: px2rem(10);
            width: px2rem(60);
            height: px2rem(40);
            transform: translate3d(0, 0, 0);

            path {
                fill: none;
                transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
                stroke-width: 40px;
                stroke-linecap: round;
                stroke:$color-white;
                stroke-dashoffset: 0px;
            }

            path.top,
            path.bottom {
                stroke-dasharray: 240px 950px;
            }
            path.middle {
                stroke-dasharray: 240px 240px;
            }

            &.cross {
                path.top,
                path.bottom {
                    stroke-dashoffset: -650px;
                    stroke-dashoffset: -650px;
                }
                path.middle {
                    stroke-dashoffset: -115px;
                    stroke-dasharray: 1px 220px;
                }
            }
        }
    }

    //LANGUAGE BUTTONS
    .languages {
        float:right;

        height: px2rem(40);
        padding: px2rem(10,20,10,10);
        line-height:px2rem(40);
        font-size:px2rem(20);
        font-weight:500;

        a {
            text-decoration: none;
            color:$color-white;
        }
    }

    .logo {
        position:relative;
        display:none;
        float:left;
        width:px2rem(300);
        height:px2rem(60);
        overflow:hidden;

        .image {
            position:relative;
            margin-left:px2rem(10);
            width:px2rem(280);
            height:px2rem(60);
            background: url('../image/logo-rockplast-white.svg') no-repeat center center;
            background-size:contain;
        }
    }

    .desktop-visible-menu & {
        @include respond-from('tablet') {
            .menu {display: none;}
            .logo {display: block;}
        }
    }
}

main {
    position:relative;
    z-index:1;
    padding:px2rem(1,0,1,0);
    box-sizing: border-box;
    @extend .clearfix;

    .desktop-visible-menu & {
        @include respond-from('tablet') {
            margin: px2rem(5, 0, 5, 300);
            //border-left: px2rem(1) solid $color-grey;
        }
    }
}

footer {
    position:relative;
    min-height: px2rem(200);
    background: $color-grey;
    @extend .clearfix;

    .decoration {
        position:absolute;
        width:100%;
        height:px2rem(5);
        background: $color-grey;
        border-bottom:px2rem(1) solid $color-white;

        .deco-body {
            position:absolute;
            top:px2rem(5);
            width:px2rem(20);
            height:px2rem(10);

            &.deco-1 {left:px2rem(40);}
            &.deco-2 {left:px2rem(105);}
        }

        .deco-part {
            position:absolute;
            width:100%;
            height:100%;
            border-bottom:px2rem(1) solid $color-white;
            background:$color-grey;

            &.deco-left {
                left:px2rem(-5);
                transform: skewX(25deg);
                border-left:px2rem(1) solid $color-white;
            }
            &.deco-right {
                transform: skewX(-25deg);
                border-right:px2rem(1) solid $color-white;
            }
        }
    }

    .row {
        padding:px2rem(30,0,25,0);
        max-width:px2rem(1200);
        margin:0 auto;
    }

    .logo {
        width:60%;
        height:px2rem(68);
        margin: 0 auto px2rem(10) auto;
        background:url('../image/logo-rockplast-white.svg') center center no-repeat;
        background-size:contain;
    }

    a.link {
        display:block;
        box-sizing:border-box;
        border:px2rem(4) solid $color-white;
        border-radius: px2rem(8);
        transition:0.5s;
        cursor:pointer;

        max-width: px2rem(300);
        margin: px2rem(20) auto px2rem(10) auto;
        padding: px2rem(5,0,5,0);

        line-height:px2rem(20);
        text-align:center;
        text-decoration:none;
        color:$color-white;

        span {display:block;}

        &:hover {
            background:$color-white;
            color:$color-grey;
            text-decoration:none;
        }
    }

    .column-contact {
        text-align:center;
        border-left:px2rem(1) solid $color-light;
        box-sizing:border-box;

        .title {
            font-size: px2rem(20);
            font-family: $font-work-sans;
            font-weight: 300;
            color: $color-light;
            margin:0 auto 0 auto;
        }

        ul.icons {
            text-align:left;
            margin:px2rem(12,0,0,0);
            padding:0;

            li {
                margin:0;
                padding:0;
                list-style: none;
                color:$color-white;
                padding:px2rem(0,6,6,12);
                line-height:px2rem(38);
                @extend .clearfix;

                a {
                    display:block;
                    width:100%;
                    text-decoration:none;
                    color:$color-white;
                    cursor:pointer;
                }

                .icon {
                    border:px2rem(4) solid $color-white;
                    border-radius: px2rem(8);
                    transition:0.5s;
                    display:block;
                    float:left;
                    width:px2rem(32);
                    height:px2rem(32);
                    margin-right:px2rem(12);
                    background:center center no-repeat;
                    background-size: px2rem(24,24);

                    &.phone     {background-image:url('../svg/white/phone-call.svg');}
                    &.mail      {background-image:url('../svg/white/email.svg');}
                    &.facebook  {background-image:url('../svg/white/facebook.svg');}
                    &.instagram {background-image:url('../svg/white/instagram.svg');}
                    &.youtube   {background-image:url('../svg/white/youtube.svg');}
                }

                a:hover {
                    .icon {
                        background-color:$color-white;

                        &.phone     {background-image:url('../svg/grey/phone-call.svg');}
                        &.mail      {background-image:url('../svg/grey/email.svg');}
                        &.facebook  {background-image:url('../svg/grey/facebook.svg');}
                        &.instagram {background-image:url('../svg/grey/instagram.svg');}
                        &.youtube   {background-image:url('../svg/grey/youtube.svg');}
                    }
                }

                //<li><a href="#"><span class="icon youtube"></span>youtube</a></li>
            }
        }
    }

    .copyright {
        padding:px2rem(8,24,8,24);
        background:$color-dark;
        color:$color-light;
    }

    @include respond-for('tablet') {
        .column-logo {
            float:none;
            width:100%;
            margin-bottom:px2rem(20);
        }
        .column-contact {
            border-left:0;
            width:50%;
        }
        .column-contact:last-child {
            border-left:px2rem(1) solid $color-light;
        }
    }

    @include respond-for('phone') {
        .columns {
            width:100%;
            float:none;
            margin-bottom:px2rem(10);
        }
        .column-contact {
            padding-top:px2rem(10);
            border-left:0;
            border-top:px2rem(1) solid $color-light;
        }
        .icons {
            max-width:px2rem(300);
            margin-left:auto !important;
            margin-right:auto !important;

            li {
                padding-left:0 !important;
            }
        }
    }
}

nav {
    position:absolute;
    z-index:2;

    width:100%;
    height:0;
    overflow:hidden;
    background:$color-grey;
    color:$color-white;

    transition:height 0.5s;

    // overall frame
    .frame {
        margin-top:px2rem(5);
        padding-bottom:px2rem(60);
        position:relative;
        float:left;
        width:50%;
        box-sizing: border-box;

        &.first {
            border-right:px2rem(1) solid $color-white;
        }
    }

    // logo
    .part-logo {
        width:100%;
        margin:px2rem(10,0,5,0);

        .logo {
            position:relative;
            width: px2rem(300);
            max-width:70%;
            height:px2rem(150);
            margin:0 auto 0 auto;
        }
    }

    // left / first
    .carbon-technology .part-logo {
        .logo {
            background: url('../image/logo-carbon-white.svg') no-repeat;
            background-size: 100% 80%;
        }
    }

    // right / last
    .climbing-walls .part-logo {
        .logo {
            background: url('../image/logo-climbing-white.svg') no-repeat;
            background-size: 100% 100%;
        }
    }

    .part-text {
        width:100%;
        margin:px2rem(10,0,5,0);

        .text {
            position:relative;
            max-width:70%;
            height:px2rem(150);
            margin:0 auto 0 auto;
        }
    }

    .part-button {
        width:100%;
        margin:px2rem(10,0,5,0);

        .link {
            display:block;
            box-sizing:border-box;
            border:px2rem(4) solid $color-white;
            border-radius: px2rem(8);
            transition: background 0.5s;
            cursor:pointer;

            max-width:70%;
            margin: px2rem(20) auto px2rem(10) auto;
            padding: px2rem(5,0,5,0);

            line-height:px2rem(20);
            text-align:center;
            text-decoration:none;
            color:$color-white;

            span {display:block;}

            &:hover {
                background:$color-white;
                color:$color-grey;
            }
        }
    }

    .part-nav {
        position:relative;
        margin:px2rem(25) auto 0 auto;
        width: px2rem(300);
        max-width:70%;

        .level1 {
            margin:0;
            padding:0;

            li {
                margin:0;
                padding:0;
                list-style: none;
            }
        }

        a {
            position:relative;
            display:block;
            padding:px2rem(6,5,6,0);
            color:$color-white;
            text-decoration:none;
            cursor:pointer;

            .deco {
                position:absolute;
                display:block;
                width:px2rem(16);
                height:px2rem(16);
                border:px2rem(2) solid $color-white;
                border-radius:px2rem(4);
                transition: background 0.5s;
            }

            .text {
                position:relative;
                top:px2rem(1);
                display:block;
                padding-left:px2rem(30);
                line-height:px2rem(20);
                transition: transform 0.5s;
                transform-origin: 0 50%;
            }

            &:hover {
                .deco {background: $color-white;}
                .text {transform:scale(1.3, 1.3);}
            }
        }

        @at-root {
            body.section-carbon-technology & li.current .deco {
                background: $color-red;
            }
        }
    }

    @include respond-to('phone') {
        .frame {
            float: none;
            width: 100%;
            padding-bottom: px2rem(20);

            &.first {
                border-right:px2rem(0);
                border-bottom:px2rem(1) solid $color-white;
                margin-bottom:px2rem(20);
            }
        }

        .part-text .text {
            height:auto;
        }
    }

    .desktop-visible-menu & {
        @include respond-from('tablet') {

            position:absolute;
            width:px2rem(300);
            height:auto;
            margin:0 !important;
            padding:0 !important;

            background:transparent;
            color:$color-grey;

            .frame {
                width:100%;
                border:0;
                margin:0 !important;
                padding:0 !important;
                //border-right:px2rem(1) solid $color-grey;

                &.second {
                    width:auto;
                    margin:px2rem(20,10,0,10) !important;
                    padding:px2rem(0,10,30,10) !important;
                    border-top:px2rem(1) solid $color-light;
                }
            }
            .part-logo {display:none;}
            .part-nav {
                margin:px2rem(15,10,10,20);
                a {
                    color:$color-grey;
                    .deco {border-color:$color-grey; background:$color-white}
                    &:hover .deco {background:$color-grey;}
                }
            }
            .part-text .text {
                max-width:none;
                height:auto;
                margin:px2rem(40,5,0,5) !important;
            }
            .part-button .link {
                border:px2rem(4) solid $color-grey;
                color:$color-grey;
                background:$color-white;
                max-width:none;

                &:hover {
                    background:$color-grey;
                    color:$color-white;
                }
            }
        }
    }

    .desktop-fixed-menu & {
        @include respond-from('tablet') {
            top:0px;
            position:fixed;
            z-index:1;

            .part-logo {
                position:relative;
                display:block;
                width:px2rem(300);
                height:px2rem(60);
                margin:px2rem(0,0,20,0);

                .logo {
                    position:relative;
                    margin-left:px2rem(10);
                    width:px2rem(280);
                    height:px2rem(59);
                    max-width:none;
                    background: url('../image/logo-rockplast-grey.svg') no-repeat center center;
                    background-size:contain;
                    border-bottom: px2rem(1) solid #aaaaaa;
                }
            }
        }
    }
}

// circle decoration

.deco-circle {
    position:fixed;
    width:120vh; height:120vh;
    top:0;
    right:-60vh;
    background:url('../image/deco-circle-color.svg') no-repeat center center;
    background-size: contain;
    opacity:0.05;
    //filter:blur(3px);

    animation-name: rotate;
    animation-duration: 300s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    @include respond-to($media-tablet) {
        display:none;
    };
}

@keyframes rotate {
    from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}
}

// menu

.deco-menu {
    @include respond-from($media-tablet) {
        position:fixed;
        left:0;
        top:0;
        bottom:0;
        width:px2rem(300);
        background:#eee;
    }
}