// usage:
// .class {
//   @extend %clearfix;
// }

// SETUP:
$geck-config-clearfix-auto-create: true;  //creates class '.clearfix'
$geck-config-clearfix-support-ie7: false; //set true, if should support IE7 and lower

%clearfix {
  @if $geck-config-clearfix-support-ie7 {

    //version for older browsers
    &:after {
      content: " ";
      visibility: hidden;
      display: block;
      height: 0;
      clear: both;
    }

  } @else {

    //simpler version
    &:after {
      content: "";
      display: table;
      clear: both;
    }

  }
}

@if $geck-config-clearfix-auto-create {
  .clearfix {
    @extend %clearfix;
  }
}
